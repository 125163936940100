import { getCachedRuntimeConfig } from 'utils/runtimeConfigUtils';
import Script from 'next/script';
import { useUserData } from 'state/userData';

const GtmScript: React.FC = () => {
    const cashedConfig = getCachedRuntimeConfig();
    const { NEXT_PUBLIC_GTM_ID: CASHED_GTM_ID } = cashedConfig;
    const gtmId = process.env.NEXT_PUBLIC_GTM_ID || CASHED_GTM_ID;
    const isLoggedIn = useUserData((store) => store.isLoggedIn);

    const script = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_auth=pNjzQU8gpvHorHbkDwZ23g&gtm_preview=env-1&gtm_cookies_win=x';
        f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
    `;

    const scriptHandler = () => {
        window.dataLayer = window.dataLayer || [];

        if (isLoggedIn)
            window.dataLayer.push({
                event: 'Login',
                value: 'true',
            });
    };

    return (
        <Script onReady={scriptHandler} id="GTMOnReadyScript">
            {script}
        </Script>
    );
};

export default GtmScript;
